import React, { useState, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import axios from 'axios'
/**
 * @typedef UserInfoType
 * @type {object}
 * @property {string | number} id - an ID.
 * @property {string} name - your name.
 * @property {string} [profileImage] - profile image.
 */

/**
 * @typedef AuthContextType
 * @type {object}
 * @property {UserInfoType} [user]
 * @property {Function} [onForbidden]
 * @property {Function} [login]
 * @property {Function} [register]
 * @property {Function} [getUserInfo]
 * @property {Function} [logout]
 * @property {any} [client]
 */

/**
 * @type {React.Context<AuthContextType>}
 */
const Context = React.createContext({})
export const AuthProvider = ({
  children,
  forbiddenPath = '/forbidden',
  loadingComponent = () => <div>Loading</div>,
  client = {
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    getUserInfo: () =>
      Promise.resolve({
        id: 1,
        name: 'Mock',
      }),
    logout: () => Promise.resolve(),
  },
}) => {
  /**
   * @type [UserInfoType, React.Dispatch<UserInfoType>]
   */
  const [user, setuser] = useState()
  const [userInfo, setUserInfo] = useState()
  const [loading, setloading] = useState(true)
  const history = useHistory()

  const debug = (text) => {
    console.log(text)
    // axios.post('https://080e-2405-9800-ba00-ce0e-d16f-cbd-91ac-4873.ngrok-free.app/logs', {
    //   text,
    // })
  }

  useEffectOnce(() => {
    const liff = window.liff
    debug('liff init')
    liff
      .init({ liffId: process.env.REACT_APP_LIFF_ID })
      .then(async () => {
        debug('after init')
        if (!liff.isLoggedIn()) {
          liff.login()
        }

        const accessToken = liff.getAccessToken()
        const isLiff = liff.isInClient()
        debug('is Liff ' + isLiff)
        if (isLiff) {
          debug('getting profile')
          const { data: profile } = await axios.get('https://api.line.me/v2/profile', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          console.log('profile', profile)
          debug('getting profile success')
          setuser(profile)
          setloading(false)
        }
      })
      .catch((e) => {
        alert(`LIFF error: ${e.message}`)
      })
  })

  const contextValue = useMemo(() => {
    const getUserInfo = async (payload) => {
      const userInfo = await client.getUserInfo(payload)
      setUserInfo(userInfo)
      return userInfo
    }
    return {
      user,
      userInfo,
      onForbidden: () => history.push(forbiddenPath),
      ...client,
      getUserInfo,
      login: async (payload) => {
        await client.login(payload)
        // return await getUserInfo()
        return
      },
      logout: async () => {
        await client.logout()
        setuser(null)
        return
      },
    }
  }, [user, userInfo, history])

  if (loading) {
    return loadingComponent()
  }
  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const useAuth = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useAuth should be use in side auth context')
  }
  return context
}
/**
 *
 * @param {function} forbiddenCb
 */
export const useAuthenticated = (forbiddenCb) => {
  const { user, onForbidden } = useAuth()
  if (!user) {
    forbiddenCb ? forbiddenCb() : onForbidden()
  }
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  forbiddenPath: PropTypes.string,
  loadingComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  client: PropTypes.object,
}
