import { Typography } from 'antd'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import * as yup from 'yup'
import { message, Image } from 'antd'

import { useAuth } from '../../contexts/AuthContext'
import { Form, TextInput, SubmitButton } from '../../components/Form'

const validationSchema = yup.object().shape({
  id: yup.string().required(),
})

const Login = () => {
  const { login, user } = useAuth()
  // const history = useHistory()
  const handleSubmit = async (formData) => {
    try {
      await login({
        ...formData,
        tokenLine: user.userId,
        imageURL: user.pictureUrl || 'https://avatar.proxied.cloud/1',
      })
      await message.success({
        // content: 'ข้อมูลของท่านถูกต้อง ท่านกำลังเข้าสู่หน้าเลือกข่าวสาร',
        content: 'ข้อมูลของท่านถูกต้อง',
        duration: 3,
        style: { marginTop: '10vh' },
      })
      window.liff.closeWindow()
      // history.push({
      //   pathname: '/preference',
      //   state: {
      //     ...formData,
      //   },
      // })
    } catch (e) {
      console.log(e)
      await message.error({
        content: 'ไม่พบหมายเลขนี้ในระบบ ท่านกำลังเข้าสู่หน้าลงทะเบียน',
        duration: 3,
        style: { marginTop: '10vh' },
      })
      // window.location = 'https://vaccicopedia.sanofi.co.th/register'
      window.location = 'https://www.campus.sanofi/th/registration'
    }
  }

  return (
    <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
      <Typography.Title
        level={4}
        style={{ textAlign: 'center', color: '#4A4A4A', fontWeight: 500, margin: '0 0 20px' }}
      >
        เลือกรับข่าวสารตามความต้องการ
      </Typography.Title>
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        กรุณาระบุเลขที่ใบประกอบวิชาชีพเวชกรรม
      </Typography.Paragraph>
      <Typography.Paragraph>(เฉพาะตัวเลข)</Typography.Paragraph>
      <TextInput formItemOptions={{ formItemLayout: null }} name="id" placeholder="123XXXXX" />
      <SubmitButton formItemOptions={{ formItemLayout: null }} label="">
        ทำต่อ
      </SubmitButton>
    </Form>
  )
}

export default Login
