import PropTypes from 'prop-types'
import { Typography, Row, Col, Layout, Image } from 'antd'
import Logo from '../images/logo.png'

function AuthLayout({ children }) {
  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff', textAlign: 'center' }}>
      <Layout.Header style={{ backgroundColor: '#fff', padding: '10px' }}>
        <Row>
          <Col span={5}></Col>
          <Col span={14}>
            <Typography.Title level={4} style={{ color: '#525CA3', margin: 0 }}>
              VACCIOPEDIA
            </Typography.Title>
            <Typography.Title level={5} style={{ color: '#525CA3', margin: 0 }}>
              by SANOFI PASTEUR
            </Typography.Title>
          </Col>
          <Col span={5}>{/* <Image width={60} src={Logo} preview={false} /> */}</Col>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ padding: '0 10px', display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '15px 30px',
          }}
        >
          {children}
        </div>
      </Layout.Content>
    </Layout>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default AuthLayout
