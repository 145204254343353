import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import Login from '../pages/Login'
import Home from '../pages/Home'
import RedirectPages from '../pages/Redirect'
const Routes = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const link = query.get('link')
  return (
    <Switch>
      <Route exact path="/redirect">
        <RedirectPages />
      </Route>
      <Route path={['/', '/login']}>
        <AppLayout>
          <Route exact path="/">
            {link ? <Redirect to={`/redirect${location.search}`} /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          {/* <Route exact path="/preference">
            <Home />
          </Route> */}
        </AppLayout>
      </Route>
    </Switch>
  )
}

export default Routes
