import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useEffectOnce } from 'react-use'
import { useLocation, Redirect } from 'react-router-dom'
import { client } from '../../utils/client'

const RedirectPage = () => {
  const { getUserInfo, user } = useAuth()
  const [isRedirect, setIsRedirect] = useState(false)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const link = query.get('link')
  const action = query.get('action')
  useEffectOnce(() => {
    checkUserInfo()
  })
  const checkUserInfo = async () => {
    try {
      await getUserInfo({ tokenLine: user.userId })
      if (action) {
        await client.post('/logs/richMenu', {
          lineUid: user.userId,
          menu: action,
          metadata: {
            url: link,
          },
        })
      } else {
        await client.post('/logs/link', {
          lineUid: user.userId,
          link,
        })
      }
      if (link) {
        window.location.href = link
      } else setIsRedirect(true)
    } catch (e) {
      setIsRedirect(true)
    }
  }
  if (isRedirect) {
    return <Redirect to="/login"></Redirect>
  }
  return <div>Loading . . .</div>
}

export default RedirectPage
