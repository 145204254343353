import Axios from 'axios'

const tokenKey = 'token'

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'X-Access-Token': localStorage.getItem(tokenKey) || '',
  },
})

const login = async (payload) => {
  const result = await instance.post('/users/register', payload).then((res) => res.data)
  // const { token } = result
  // if (token) {
  //   localStorage.setItem(tokenKey, token)
  //   instance.defaults.headers['X-Access-Token'] = token
  // }
  return result
}

const getUserInfo = async (payload) => {
  const result = await instance.post('/users/checkuser', payload).then((res) => res.data)
  return result
}

const logout = async () => {
  localStorage.removeItem(tokenKey)
  instance.defaults.headers['Authorization'] = ''
  return {}
}

export const client = instance

const authClient = {
  login,
  logout,
  getUserInfo,
  client,
}

export default authClient
